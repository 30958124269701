<template>
  <b-card no-body class="card-revenue-budget" v-if="loading">
    <b-card-header>
      <b-card-title>
        รายได้ ครีเอเตอร์ & แอดมิน
      </b-card-title>

      <b-card-text class="mr-25 mb-0 text-muted">
        <!-- Updated 1 month ago -->
        หลังหักส่วนต่าง (ถ้าเป็นแอดมินจะแสดงราคาเต็ม)
      </b-card-text>
    </b-card-header>
    <vue-apex-charts id="budget-chart" height="350" type="bar" :options="_options" :series="_series" />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    _series() {
      try {
        return [
          {
            data: this.results?.partnerRemuneration?.datas.filter(i => i > 0),
          },
        ]
      } catch (error) {
        debugger
      }
    },
    _options() {
      const self = this
      try {
        return {
          chart: {
            type: 'bar',
            height: 380,
          },
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#F30808'],
            },
            formatter: function(val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + `${self.withCommas(val)}`
            },
            offsetX: 0,
            dropShadow: {
              enabled: false,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          xaxis: {
            categories: this.results?.partnerRemuneration?.names,
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function(value) {
                return `${self.withCommas(value)}`
              },
            },
          },

          tooltip: {
            theme: 'light',
            x: {
              show: false,
            },
            // แสดงชื่อ
            y: {
              title: {
                formatter: function(c, x) {
                  return `${x.w.globals.labels[x.dataPointIndex]} ::`
                },
              },
            },
          },
        }
      } catch (error) {
        debugger
      }
    },
  },
  data() {
    return {
      revenue_report: {},
      // budget chart
      budgetChart: {
        series: [
          {
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 380,
          },
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          colors: [
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
            '#69d2e7',
          ],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              // colors: ['#fff'],
              colors: [$themeColors.primary, $themeColors.warning],
            },
            formatter: function(val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + this.withCommas(val)
            },
            offsetX: 0,
            dropShadow: {
              enabled: true,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          xaxis: {
            categories: [
              'South Korea',
              'Canada',
              'United Kingdom',
              'Netherlands',
              'Italy',
              'France',
              'Japan',
              'United States',
              'China',
              'India',
            ],
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          // title: {
          //   text: 'รายได้ Partner',
          //   align: 'center',
          //   floating: true,
          // },
          // subtitle: {
          //   text: 'Category Names as DataLabels inside bars',
          //   align: 'center',
          // },
          tooltip: {
            theme: 'dark',
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function() {
                  return ''
                },
              },
            },
          },
        },
      },
    }
  },
  created() {},
}
</script>
